import {
    ChatContainer,
    ConversationHeader,
    MainContainer,
    Message,
    MessageInput,
    MessageList,
    MessageSeparator,
    TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { API } from "aws-amplify";
import { useState } from "react";
import { ReactComponent as Logo } from '../../images/FWC-Logo.svg';
import { getAuthToken } from "../../utils/AuthUtils";
import './styles.scss';
import { Button } from "react-bootstrap";

const welcomeMessage = {
    id: 1,
    message: "Hello! I'm FRED. How can I help you today?",
    senderName: "FRED",
    senderAvatar: Logo,
}

export const QueryFilingsChat = () => {
    const [messages, setMessages] = useState([welcomeMessage]);
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [sessionId, setSessionId] = useState(null);

    const addMessage = async (message) => {
        setLoadingResponse(true);
        const chatMessages = [...messages, { id: messages.length + 1, message, senderName: "You", senderAvatar: Logo }];
        setMessages(chatMessages);
        const response = await queryKnowledgeBase(message);
        setSessionId(response.sessionId);
        const chatBotResponse = formatChatBotResponse(response);
        const botResponseMessage = {
            id: chatMessages.length + 2,
            message: chatBotResponse,
            senderName: "FRED",
            senderAvatar: Logo
        };
        setMessages([...chatMessages, botResponseMessage]);
        setLoadingResponse(false);
    }

    const formatChatBotResponse = (response) => {
        const referenceCitations = response.referenceCitations;
        let referencesMessage = `${response.modelResponse}`;
        if (referenceCitations.length > 0) {
            referencesMessage += "\n\nSources: ";
            referencesMessage += referenceCitations.map(citation => {
                const retrievedText = citation.retrievedText;
                const firstThreeWords = retrievedText.split(' ').slice(0, 3).join(' ');
                const lastThreeWords = retrievedText.split(' ').slice(-3).join(' ');
                const encodedText = `${encodeURIComponent(firstThreeWords).replace(/ /g, '%20')},${encodeURIComponent(lastThreeWords).replace(/ /g, '%20')}`
                const linkWithTextFragment = `${citation.edgarUrl}#:~:text=${encodedText}`;
                return `<a href="${linkWithTextFragment}" target="_blank" rel="noopener noreferrer">${citation.companyName} DEF-14A</a>`;
            }).join(', ');
        }
        return referencesMessage;
    }

    const queryKnowledgeBase = async (query) => {
        try {
            const token = await getAuthToken();
            const response = await API.get('AnnualCompensations', '/knowledge-base/filings', {
                headers: {
                    token
                },
                queryStringParameters: {
                    query,
                    sessionId
                }
            });
            return response;
        } catch (error) {
            console.error(error);
            return {
                modelResponse: "I'm sorry, I couldn't find any information on that topic.",
                referenceCitations: []
            };
        }
    }

    const clearChat = () => {
        setMessages([welcomeMessage]);
        setSessionId(null);
    }

    return (
        <div className="query-filings-chatbot">
            <MainContainer responsive={true}>
                <ChatContainer>
                    <ConversationHeader>
                        <ConversationHeader.Content userName="FRED" info="Online" />
                        <ConversationHeader.Actions>
                            <ConversationHeader.Content>
                                <Button className="clear-chat-btn" onClick={clearChat}>Clear Chat</Button>
                            </ConversationHeader.Content>
                        </ConversationHeader.Actions>
                    </ConversationHeader>
                    <MessageList typingIndicator={loadingResponse && <TypingIndicator content="FRED is thinking..." />}>
                        {messages.map((message) => (
                            <Message
                                key={message.id}
                                model={{
                                    message: message.message,
                                    sentTime: "Just now",
                                    sender: message.senderName,
                                    direction: message.senderName === "FRED" ? "incoming" : "outgoing",
                                }}
                                avatar={message.senderAvatar}>
                                <Message.Footer>{message.senderName}</Message.Footer>
                            </Message>
                        ))}
                    </MessageList>
                    <MessageInput
                        attachDisabled
                        attachButton={false}
                        autoFocus
                        placeholder="Type your query here"
                        onSend={(message) => addMessage(message)}
                        sendDisabled={loadingResponse}>
                        <MessageSeparator>
                            {loadingResponse && <TypingIndicator content="FRED is thinking..." />}
                        </MessageSeparator>
                    </MessageInput>
                </ChatContainer>
            </MainContainer>
        </div >);
};